import {
  CareCategories,
  Constants,
  LOB,
  MemberCoverageTypeToUICoverageTypeConversion,
  NULL_RESULTS,
  ProviderTypes,
} from '../../../../common/Constants';
import {
  convertProviderTypeToAdobeType,
  getDisplayedProviderCardsCount,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import {
  getCoverageTypes,
  getNetworkIdsForPES,
} from '../../../../utils/user.utils';
import {
  getModifiedPayloadForProviderSearch,
  hideResultSection,
} from './utils';

export function getProviderType(providerType) {
  if (providerType === '') {
    return `${convertProviderTypeToAdobeType(
      ProviderTypes.PRACTITIONER
    )}|${convertProviderTypeToAdobeType(
      ProviderTypes.ORGANIZATION
    )}|${convertProviderTypeToAdobeType(ProviderTypes.MEDICAL_GROUP)}`;
  }
  return convertProviderTypeToAdobeType(providerType);
}
export const formatData = (practitionerData, orgdata, medGrpData) => {
  const data: any = {};
  data.totalCountPractitioners = practitionerData.totalCountPractitioners || 0;
  data.totalCountOrganizations = orgdata.totalCountOrganizations || 0;
  data.totalCountMedicalGroup = medGrpData.totalCountMedicalGroup || 0;
  data.practitionerSearchRadius = practitionerData.practitionerSearchRadius;
  data.organizationSearchRadius = orgdata.organizationSearchRadius;
  data.medicalGroupSearchRadius = medGrpData.medicalGroupSearchRadius;
  data.medicalGroup = medGrpData.medicalGroup || [];
  data.facilities = orgdata.facilities || [];
  data.providers = practitionerData.providers || [];
  data.isTieredPlan = practitionerData.isTieredPlan || orgdata.isTieredPlan;
  data.isLoaded = true;
  return data;
};

export const checkError = (providerHasError, orgHasError, medGrpHasError) => {
  const regEx = /4\d{2}|5\d{2}/;
  return (
    regEx.test(providerHasError?.status) &&
    regEx.test(orgHasError?.status) &&
    regEx.test(medGrpHasError?.status)
  );
};

export const isEmptyResult = (
  choosePCP,
  isLoading,
  resultType,
  totalCountOrganizations,
  totalCountMedicalGroup,
  totalCountPractitioners
) =>
  !isLoading &&
  choosePCP &&
  (resultType === NULL_RESULTS ||
    (totalCountOrganizations === 0 &&
      totalCountMedicalGroup === 0 &&
      totalCountPractitioners === 0));

export const getPayloadWithRecriprocityId = ({
  currentMember,
  onlineRetailers,
  featureFlags,
  pseudoVerticalsDetails,
  pseudoVerticals,
}) => {
  const memberCoverageTypes = getCoverageTypes(currentMember);

  const payloadWithRecriprocityId = JSON.parse(
    JSON.stringify(Constants.CARE_CATEGORIES_NAME_PAYLOAD)
  )
    .filter((category) =>
      memberCoverageTypes.some((cov) =>
        category?.coverages?.includes(
          MemberCoverageTypeToUICoverageTypeConversion[cov]
        )
      )
    )
    .map((category) => {
      const reciprocityId = decodeURIComponent(
        getNetworkIdsForPES(
          currentMember,
          category?.coverages?.[0],
          featureFlags
        )
      );

      if (
        category.chipCategory === 'health-supplies' ||
        category.chipCategory === 'labs-and-services'
      ) {
        return {
          ...category,
          reciprocityId: `${reciprocityId}`,
        };
      }

      return {
        ...category,
        onlineRetailer: onlineRetailers,
        reciprocityId,
      };
    });

  if (pseudoVerticals && pseudoVerticalsDetails?.categoryChips) {
    return payloadWithRecriprocityId.filter((category) =>
      pseudoVerticalsDetails.categoryChips.includes(category.chipCategory)
    );
  }
  return payloadWithRecriprocityId;
};

export const getNoOfResultsForAnalytics = (data) => {
  let totalDisplayedProviderCardsCount = 0;
  totalDisplayedProviderCardsCount = getDisplayedProviderCardsCount(
    totalDisplayedProviderCardsCount,
    data?.totalCountPractitioners || 0
  );
  totalDisplayedProviderCardsCount = getDisplayedProviderCardsCount(
    totalDisplayedProviderCardsCount,
    data?.totalCountMedicalGroup || 0
  );
  totalDisplayedProviderCardsCount = getDisplayedProviderCardsCount(
    totalDisplayedProviderCardsCount,
    data?.totalCountOrganizations || 0
  );

  return totalDisplayedProviderCardsCount;
};

export const getProviderTypesFromDisplayedResults = (data) => {
  let providerTypesFromDisplayedProviderCards = '';
  if (data?.totalCountPractitioners && data?.totalCountPractitioners > 0) {
    providerTypesFromDisplayedProviderCards = convertProviderTypeToAdobeType(
      ProviderTypes.PRACTITIONER
    );
  }
  if (data?.totalCountOrganizations && data?.totalCountOrganizations > 0) {
    providerTypesFromDisplayedProviderCards =
      providerTypesFromDisplayedProviderCards !== ''
        ? `${providerTypesFromDisplayedProviderCards}|${convertProviderTypeToAdobeType(
            ProviderTypes.ORGANIZATION
          )}`
        : convertProviderTypeToAdobeType(ProviderTypes.ORGANIZATION);
  }
  if (data?.totalCountMedicalGroup && data?.totalCountMedicalGroup > 0) {
    providerTypesFromDisplayedProviderCards =
      providerTypesFromDisplayedProviderCards !== ''
        ? `${providerTypesFromDisplayedProviderCards}|${convertProviderTypeToAdobeType(
            ProviderTypes.MEDICAL_GROUP
          )}`
        : convertProviderTypeToAdobeType(ProviderTypes.MEDICAL_GROUP);
  }

  return providerTypesFromDisplayedProviderCards;
};

export const groupSpecialtyRollupCodes = (
  includeSpecialityRollupCodes: string | undefined,
  searchType: string,
  specialtySearch: boolean
) => {
  if (
    includeSpecialityRollupCodes &&
    (searchType === Constants.SEARCH_TYPES.SPECIALTY || specialtySearch)
  ) {
    return includeSpecialityRollupCodes.split(',');
  }
  return undefined;
};

const isSpecialitySearch = (
  params: any,
  categoryPayload: any = {}
): boolean => {
  const isBehavioralProgramIdExists =
    categoryPayload?.behavioralProgramId?.length;
  const isGenderExists = params?.gender?.length;
  const isVirtualCareExists = params?.virtualCare;
  const isAcceptsEapExists =
    params?.acceptsEap && params?.providerType !== ProviderTypes.ORGANIZATION;
  const isOnlineRetailers = params?.onlineRetailers;
  return (
    !!isBehavioralProgramIdExists ||
    !!isGenderExists ||
    !!isVirtualCareExists ||
    !!isAcceptsEapExists ||
    !!isOnlineRetailers
  );
};

export const getProviderSearchPayloadWithRecriprocityId = (
  memberCoverageTypes,
  featureFlags,
  currentMember,
  coverageType,
  choosePCP,
  onlineRetailers
) =>
  JSON.parse(JSON.stringify(Constants.CARE_CATEGORIES_NAME_PAYLOAD))
    .filter((category) =>
      memberCoverageTypes.some((cov) =>
        category?.coverages?.includes(
          MemberCoverageTypeToUICoverageTypeConversion[cov]
        )
      )
    )
    .map((category) => {
      const coverage = choosePCP ? coverageType : category?.coverages?.[0];
      const reciprocityId = decodeURIComponent(
        getNetworkIdsForPES(currentMember, coverage, featureFlags)
      );

      if (
        category.chipCategory === 'health-supplies' ||
        category.chipCategory === 'labs-and-services'
      ) {
        return {
          ...category,
          reciprocityId: `${reciprocityId}`,
        };
      }

      return {
        ...category,
        reciprocityId,
        onlineRetailer: onlineRetailers,
      };
    });

export const getPractitionersData = (
  practitionerParams,
  selectedCategoryChip,
  selectedCategoryPayload,
  callProvidersResult: (x, y) => any,
  setShowProviders: (x: boolean) => any
) => {
  if (
    (practitionerParams.includeSpecialityRollupCodes?.length > 0 ||
      practitionerParams.isUserTextSearch ||
      practitionerParams.typeaheadAoeCodes?.length ||
      practitionerParams.conditionAoeCodes?.length ||
      isSpecialitySearch(practitionerParams, selectedCategoryPayload)) &&
    (selectedCategoryChip === CareCategories.MEDICAL_SPECIALISTS ||
      selectedCategoryChip === CareCategories.DENTAL ||
      selectedCategoryChip === CareCategories.VISION ||
      selectedCategoryChip === CareCategories.PRIMARY_CARE ||
      selectedCategoryChip === CareCategories.BEHAVIORAL_HEALTH)
  ) {
    callProvidersResult(practitionerParams, 'P');
  } else setShowProviders(false);
};

export const getMedGrpData = (
  medGrpParams,
  selectedCategoryChip,
  selectedCategoryPayload,
  callProvidersResult: (x, y) => any,
  setShowProviderGroups: (x: boolean) => any
) => {
  if (
    (medGrpParams.includeSpecialityRollupCodes.length > 0 ||
      medGrpParams.isUserTextSearch ||
      medGrpParams.typeaheadAoeCodes?.length ||
      medGrpParams.conditionAoeCodes?.length ||
      isSpecialitySearch(medGrpParams, selectedCategoryPayload)) &&
    (selectedCategoryChip === CareCategories.MEDICAL_SPECIALISTS ||
      selectedCategoryChip === CareCategories.PRIMARY_CARE ||
      selectedCategoryChip === CareCategories.BEHAVIORAL_HEALTH)
  ) {
    callProvidersResult(medGrpParams, 'M');
  } else setShowProviderGroups(false);
};

export const getOrgData = (
  orgParams,
  selectedCategoryChip,
  selectedCategoryPayload,
  callProvidersResult: (x, y) => any,
  setShowFacilities: (x: boolean) => any
) => {
  if (
    (orgParams.includeSpecialityRollupCodes.length > 0 ||
      orgParams.isUserTextSearch ||
      selectedCategoryPayload?.behavioralFacilityProgram ||
      isSpecialitySearch(orgParams, selectedCategoryPayload)) &&
    (selectedCategoryChip === CareCategories.VISION ||
      selectedCategoryChip === CareCategories.FACILITIES ||
      selectedCategoryChip === CareCategories.HEALTH_SUPPLIES ||
      selectedCategoryChip === CareCategories.PRIMARY_CARE ||
      selectedCategoryChip === CareCategories.BEHAVIORAL_HEALTH)
  ) {
    callProvidersResult(orgParams, 'O');
  } else setShowFacilities(false);
};

type GetMixedResultsCall = {
  callProvidersResult: (params: any, code: any) => void;
  setShowProviders: (x: boolean) => any;
  setShowProviderGroups: (x: boolean) => any;
  setShowFacilities: (x: boolean) => any;
  practitionerParams: any;
  medGrpParams: any;
  orgParams: any;
  pseudoVerticalsDetails: any;
  selectedCategory: any;
  selectedCategoryPayload: any;
  searchType: string;
  choosePCP: boolean;
  cleanUpProviderCalls: boolean;
  memberLob: LOB;
};

export const getDataForMixedResults = ({
  callProvidersResult,
  setShowProviders,
  setShowFacilities,
  setShowProviderGroups,
  practitionerParams,
  orgParams,
  medGrpParams,
  pseudoVerticalsDetails,
  selectedCategory,
  searchType,
  choosePCP,
  cleanUpProviderCalls,
  memberLob,
  selectedCategoryPayload,
}: GetMixedResultsCall) => {
  if (pseudoVerticalsDetails?.isPseudoRollupSearch) {
    setShowProviders(false);
    setShowFacilities(false);
    setShowProviderGroups(false);
    pseudoVerticalsDetails?.providerType?.split(',').forEach((type) => {
      switch (type) {
        case 'M': {
          callProvidersResult(medGrpParams, 'M');
          break;
        }
        case 'P': {
          callProvidersResult(practitionerParams, 'P');
          break;
        }
        case 'O': {
          callProvidersResult(orgParams, 'O');
          break;
        }
        default: {
          break;
        }
      }
    });
  } else if (
    searchType === Constants.SEARCH_TYPES.SPECIALTY &&
    !choosePCP &&
    cleanUpProviderCalls
  ) {
    const selectedCategoryChip = selectedCategory?.label;
    getPractitionersData(
      practitionerParams,
      selectedCategoryChip,
      selectedCategoryPayload,
      callProvidersResult,
      setShowProviders
    );
    getOrgData(
      orgParams,
      selectedCategoryChip,
      selectedCategoryPayload,
      callProvidersResult,
      setShowFacilities
    );
    getMedGrpData(
      medGrpParams,
      selectedCategoryChip,
      selectedCategoryPayload,
      callProvidersResult,
      setShowProviderGroups
    );
    hideResultSection(
      selectedCategoryChip,
      setShowFacilities,
      setShowProviderGroups,
      setShowProviders
    );
  } else {
    callProvidersResult(practitionerParams, 'P');

    if (!(choosePCP && memberLob === LOB.MNR)) {
      callProvidersResult(orgParams, 'O');
      callProvidersResult(medGrpParams, 'M');
    }
  }
};

export const resolveCoverageType = (coverageTypes: string | string[]) =>
  Array.isArray(coverageTypes) ? coverageTypes : coverageTypes.split(',');

export const resolveIncludeSpecialityRollupCodesAndUpdateStore = (
  isGlobalSearch: boolean,
  globalSeachParams: any,
  combinedIncludeSpecialityRollupCodes: any,
  setTypeaheadSearchStore: (values: any) => void,
  typeaheadSearchStore: any
) => {
  const { includeSpecialityRollupCodes = '' } = globalSeachParams;
  if (isGlobalSearch) {
    if (
      includeSpecialityRollupCodes &&
      includeSpecialityRollupCodes !==
        typeaheadSearchStore.combinedIncludeSpecialityRollupCodes
    ) {
      setTypeaheadSearchStore({
        includeSpecialityRollupCodes,
        combinedIncludeSpecialityRollupCodes: includeSpecialityRollupCodes,
      });
      return includeSpecialityRollupCodes;
    }
    return (
      typeaheadSearchStore.combinedIncludeSpecialityRollupCodes ||
      combinedIncludeSpecialityRollupCodes
    );
  }

  return combinedIncludeSpecialityRollupCodes;
};

export const getProviderSearchPayload = (
  payloadWithRecriprocityId,
  isSpecialtySearch,
  searchType,
  isUserTextSearch,
  typeaheadAoeCodes,
  enableBHAoeTypeahead,
  includeSpecialityRollupCodes,
  isPseudoRollupSearch,
  uesTranslation,
  search,
  keyword,
  coverageTypes,
  bhProgramFacilityAgg,
  enableAoeMedical
) => {
  const codeByGroup = !isPseudoRollupSearch
    ? groupSpecialtyRollupCodes(
        includeSpecialityRollupCodes,
        searchType,
        isSpecialtySearch
      )
    : undefined;
  return getModifiedPayloadForProviderSearch(
    payloadWithRecriprocityId,
    codeByGroup,
    uesTranslation,
    search,
    keyword,
    isUserTextSearch,
    resolveCoverageType(coverageTypes),
    typeaheadAoeCodes,
    enableBHAoeTypeahead,
    searchType,
    bhProgramFacilityAgg,
    enableAoeMedical
  );
};
