import { storage } from '@abyss/web/tools/storage';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import find from 'lodash/find';
import { Fragment, useContext, useEffect, useState } from 'react';

import { Constants, LOB } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import {
  MOCK_MEMBERS_INFO,
  MOCK_STAGE_DEFAULT_MEMBER_INFO,
} from '../../../../common/MockMemberInfo';
import { dateGroupByYearMonthDay } from '../../../../common/Utils/datesUtils/formatDate';
import { getFeatureFlag } from '../../../../common/Utils/getFeatureFlag';
import { getSuppressedBehavioralHealth } from '../../../../common/Utils/memberUtils/memberUtils';
import { PortalContext } from '../../../../context/PortalContext';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useOBAPI } from '../../../../hooks/useOBAPI';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { loginNotRequired, stageEnv } from '../../../../utils/session.utils';
import { modifyRulesPackageKey } from '../../../../utils/user.utils';
import { IChildrenProp } from '../../types';

export const Obapi = ({ children }: IChildrenProp) => {
  const { bigN } = useStore(StoreKeys.PLAN_SELECTOR);
  const setMemberState = useStore(StoreKeys.SET_OBAPI_MEMBERS);
  const isLocal = loginNotRequired();

  const isStageEnv = stageEnv();

  const [members, setMembers] = useOBAPI({});
  const { isLoading } = members;
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const tierBenefits = useLagoon('tier-benefit')();
  const requestReciprocityId = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.REQUEST_RECIPROCITY_ID
  );
  const enableMnrCosmos = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MNR_COSMOS
  );

  const enableCns = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_CNS
  );
  const showIsPreEffectiveIndicator = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.SHOW_ISPREEFFECTIVE_INDICATOR
  );
  const extractProductCodeMarketTypeForUspNonOx = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS
      .ENABLE_EXTRACT_PRODUCT_CODE_MARKET_TYPE_FOR_USP_NON_OXFORD
  );

  const policyFilterToggle = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.MEMBER_INFO_POLICY_FILTER
  );
  const suppressVisionCoverage = !getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VISION_CHIP_ENABLED
  );
  const suppressDentalCoverage = !getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DENTAL_CHIP_ENABLED
  );
  const useNewReferralsIndicator = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_NEW_REFERRALS_INDICATOR
  );

  const enableMemberInfoCache = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MEMBER_INFO_CACHE
  );

  const { DEMO_MEMBER_INFO, USE_PRE_PROD } = Constants.STORAGE_KEYS.SESSION;

  const [enablePreProd] = useState(storage.session.get(USE_PRE_PROD, false));
  useEffect(() => {
    storage.session.set(USE_PRE_PROD, enablePreProd);
  }, [enablePreProd]);

  const {
    firstName,
    lastName,
    groupNumber,
    memberId,
    dob,
    id,
    userName,
    portalSource,
    lob,
    usePreProd,
  } = storage.session.get(DEMO_MEMBER_INFO) || {};

  const fpcPsxRedirection = useLagoon(
    Constants.LAGOON_TABLE.FPC_PSX_REDIRECTION
  )();
  const eligiblePolicyIds =
    fpcPsxRedirection?.[0]?.eligiblePolicyNumbers?.split(',') || [];

  const eligibleGroupIds =
    fpcPsxRedirection?.[0]?.groupNumber?.split(',') || [];

  const enableGroupNumberBasedRouting = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_GROUP_NUMBER_BASED_ROUTING
  );

  const { portalData } = useContext(PortalContext);
  const delsysCode = portalData.portalEligibilityData?.delsysCode;
  const enablePcp = portalData.portalEligibilityData?.enablePcp;

  const featureSuppression = useLagoon(
    Constants.LAGOON_TABLE.FEATURE_SUPPRESSION
  )();

  const behavioralHealthLagoonData = find(featureSuppression, {
    DNT_feature: ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.BEHAVIORAL_HEALTH,
    active: true,
  });
  const memberPolicyid =
    portalData.portalEligibilityData?.policyId || groupNumber;
  const suppressBehavioralCoverage = getSuppressedBehavioralHealth(
    behavioralHealthLagoonData,
    memberPolicyid,
    delsysCode
  );

  useEffect(() => {
    if (userName || memberId || portalData.portalEligibilityData) {
      setMembers({
        variables: {
          ...((lob === LOB.ENI ||
            lob === LOB.CNS ||
            lob === LOB.IFP ||
            portalData.portalEligibilityData) && {
            firstName: portalData.portalEligibilityData?.firstName || firstName,
            lastName: portalData.portalEligibilityData?.lastName || lastName,
            groupNumber:
              portalData.portalEligibilityData?.policyId || groupNumber,
            memberId: portalData.portalEligibilityData?.memberId || memberId,
            dob: portalData.portalEligibilityData?.dob || dob,
          }),
          ...(lob === LOB.MNR && {
            id,
            userName,
            portalSource,
          }),
          lob,
          usePreProd:
            usePreProd || portalData?.portalEligibilityData?.useObapiPreprod,
          enableMnrCosmos,
          enableCns,
          requestReciprocityId,
          eligiblePolicyIds,
          eligibleGroupIds,
          enableGroupNumberBasedRouting,
          showIsPreEffectiveIndicator,
          extractProductCodeMarketTypeForUspNonOx,
          policyFilterToggle,
          useNewReferralsIndicator,
          ...(portalData.portalEligibilityData && {
            suppressBehavioralCoverage,
            portalEligibilityData: {
              delsysCode,
              enablePcp,
            },
          }),
          portalSource: portalData?.portalName || portalSource,
          suppressVisionCoverage,
          suppressDentalCoverage,
          enableMemberInfoCache,
        },
      });
    } else if (
      !isLocal &&
      (bigN?.MemberNumber?.length || bigN?.userName?.length)
    ) {
      setMembers({
        variables: {
          firstName: bigN.FirstName,
          lastName: bigN.LastName,
          groupNumber: bigN.GroupNumber,
          memberId: bigN.MemberNumber,
          dob: bigN.DOB ? dateGroupByYearMonthDay(bigN.DOB) : undefined,
          id: bigN.id,
          userName: bigN.userName,
          portalSource: bigN.portalSource,
          lob: '',
          requestReciprocityId,
          eligiblePolicyIds,
          eligibleGroupIds,
          enableGroupNumberBasedRouting,
          usePreProd: enablePreProd,
          enableMnrCosmos,
          enableCns,
          showIsPreEffectiveIndicator,
          extractProductCodeMarketTypeForUspNonOx,
          policyFilterToggle,
          useNewReferralsIndicator,
          suppressVisionCoverage,
          suppressDentalCoverage,
        },
      });
    }
  }, [bigN]);

  useEffect(() => {
    if (members.data) {
      const memberInfo = modifyRulesPackageKey(
        members.data.memberInfo,
        tierBenefits
      );
      setMemberState(memberInfo);
    }
    if (isStageEnv && !members.data) {
      setMemberState(MOCK_STAGE_DEFAULT_MEMBER_INFO);
    }
    if (isLocal && !members.data) {
      setMemberState(MOCK_MEMBERS_INFO);
    }
  }, [members]);

  return (
    <Fragment>
      {isLoading ? (
        <LoadingSpinner
          ariaLoadingLabel="Loading page"
          isLoading={isLoading}
          size="$sm"
        />
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </Fragment>
  );
};
