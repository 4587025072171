import { storage } from '@abyss/web/tools/storage';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import {
  Constants,
  MemberCoverageTypeToUICoverageTypeConversion,
} from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { mappingRollUpCodeToCategory } from '../../common/RecentActivity/helper';
import {
  convertTypeaheadProviderIdAndType,
  getTypeAheadCategory,
} from '../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { useFacilitiesResult } from '../../hooks/useFacilities';
import { useSpecialtiesResult } from '../../hooks/useSpecialties';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../store/useDetailsStore';
import { usePCPStore } from '../../store/usePCPStore';
import { useSearchStore } from '../../store/useSearchStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import {
  BrowseAllVariant,
  getResolvedSource,
  handleOnCommonlySearchedClick,
  handleOnSpecialtyOrFacilityClick,
  handleSearchBarSpecialtySuggestionClickOrEnter,
} from '../../utils/typeahead.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getDependentInfo,
  getOnlineRetailers,
} from '../../utils/user.utils';
import { useAutoCompleteResults } from '../useAutoCompleteQuery';
import { useFeatureFlag } from '../useFeatureFlag';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import { retriveTypeAheadDataFromAutoComplete } from './utils';

const parseSearchParams = (searchParams: URLSearchParams) => {
  const params = Object.fromEntries(searchParams.entries());
  const { searchType = '', search = '', searchTerm = '', source } = params;

  return {
    searchType,
    search,
    searchTerm: searchTerm || search,
    source,
  };
};

interface Props {
  onTypeAheadDataLoaded: (variables: any) => void;
}

export const useTypeAheadData = ({ onTypeAheadDataLoaded }: Props) => {
  const [searchParams] = useSearchParams();
  const parsedSearchParams = parseSearchParams(searchParams);
  // Independent variables to uniquely identify the search
  const { search, searchTerm, searchType, source } = parsedSearchParams;

  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: any) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );
  const { setPCPSearchState, choosePCP, dependentSeqNbr } = usePCPStore(
    useShallow((state: any) => ({
      setPCPSearchState: state.setPCPSearchState,
      choosePCP: state.pcpSearchState.choosePCP,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: any) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );

  const { setSearchStore } = useSearchStore(
    useShallow((state: any) => ({
      setSearchStore: state.setSearchStore,
    }))
  );

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { setCoverageType, setChipValue } = chipStore;

  const { typeaheadSearchStore, setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: any) => ({
      typeaheadSearchStore: state.typeaheadSearchStore,
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );

  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();

  const sourceData = source?.split(':');
  const lagoonTableName =
  sourceData?.length > 1 ? sourceData[1] : source;
  const searchLinkName = source?.split(':')?.[1];
  const commonSearchesLagoon = useLagoon(
    lagoonTableName || Constants.LAGOON_TABLE.COMMON_SEARCHES
  )();

  const storedLocation = useGeoLocationStorage();
  const { longitude, latitude } = storedLocation;

  const [
    enableRetriveTypeAheadDataFlag,
    enableSearchResultsV2,
    enableUESSuggestionMatch,
  ] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_RETRIVE_TYPEAHEAD_DATA,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SEARCH_RESULTS_V2,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_UES_SUGGESTION_MATCH,
  ]);

  const enableRetriveTypeAheadData =
    enableRetriveTypeAheadDataFlag && enableSearchResultsV2;

  const specialityPreferenceTable = useLagoon(
    Constants.LAGOON_TABLE.SPECIALITY_PREFERENCE
  )().map((item) => ({
    rollupCode: item.rollupCode,
    specialityCode: item.specialityCode,
  }));

  const { data: facilities = [] } = useFacilitiesResult({});
  const { data: specialties = [] } = useSpecialtiesResult({});

  const memberCoverages = getCoverageTypes(currentMember);
  const convertedMemberCoverages = memberCoverages?.map(
    (cov) => MemberCoverageTypeToUICoverageTypeConversion[cov]
  );
  const dentalCoverage = convertedMemberCoverages?.find((cov) => cov === 'D');
  const visionCoverage = convertedMemberCoverages?.find((cov) => cov === 'V');
  const behavioralCoverage = convertedMemberCoverages?.find(
    (cov) => cov === 'B'
  );
  const medicalCoverage = convertedMemberCoverages?.find((cov) => cov === 'M');
  const getCoverageType = [
    dentalCoverage,
    visionCoverage,
    medicalCoverage,
    behavioralCoverage,
  ];

  const [, GetAutoCompleteResults] = useAutoCompleteResults({
    onCompleted: (data) => {
      // This retrieves typeahead data from AutoCompleteResults and calls the onTypeAheadDataLoaded function
      retriveTypeAheadDataFromAutoComplete({
        ...data,
        search,
        searchLinkName,
        searchType,
        enableRetriveTypeAheadData,
        enableUESSuggestionMatch,
        specialityPreferenceTable,
        getCoverageType,
        dependentSeqNbr,
        choosePCP,
        chipStore,
        setTypeaheadSearchStore,
        setAnalyticsState,
        setPCPSearchState,
        setChipValue,
        setCoverageType,
        setDetailsStore,
        currentMember,
        convertTypeaheadProviderIdAndType,
        getTypeAheadCategory,
        onTypeAheadDataLoaded,
      });
    },
  });

  const newSearch = `${search}-${getResolvedSource(source)}-${latitude}-${longitude}`;

  useEffect(() => {
    if (!facilities.length || !specialties.length) {
      return;
    }
    // If the feature flag is off, retrive the typeahead data from the store.
    if (!enableRetriveTypeAheadData) {
      onTypeAheadDataLoaded(typeaheadSearchStore);
    } else {
      const resolvedSource = source?.split(':')?.[0];
      // If the feature flag is on, retrive the typeahead data similar to original flow.
      const isGlobalSearch = resolvedSource === Constants.GLOBAL_SEARCH;
      const isCommonSearch = resolvedSource === 'common-searches';
      const isBrowseForCareSearch = resolvedSource === 'browse-for-care';
      const isSpecialtyDrawerSearch = resolvedSource === 'specialty-drawer';
      const isFacilitiesDrawerSearch = resolvedSource === 'facilities-drawer';
      const isRecentsearches = resolvedSource === 'recent-searches';
      const isAutoCompleteSearch =
        resolvedSource === 'auto-complete' || source === 'deeplink';

      const recentSearchesKey = `${search}:${source}`;
      const recentSearchestypeaheadValues =
        storage.session.get('recent-searches')?.[recentSearchesKey];

      if (isRecentsearches && recentSearchestypeaheadValues) {
        onTypeAheadDataLoaded(recentSearchestypeaheadValues);
      } else if (isCommonSearch) {
        const value = commonSearchesLagoon.find(
          ({ psxKeyword }) => search === psxKeyword
        );
        const typeaheadValues = handleSearchBarSpecialtySuggestionClickOrEnter({
          enableRetriveTypeAheadData,
          specialityPreferenceTable,
          value,
          searchTerm,
          commonSearch: true,
          getCoverageType,
          dependentSeqNbr,
          choosePCP,
          chipStore,
          setTypeaheadSearchStore,
          setAnalyticsState,
          setPCPSearchState,
          setChipValue,
          setCoverageType,
          setDetailsStore,
          navigate: undefined,
        });
        onTypeAheadDataLoaded(typeaheadValues);
      } else if (isBrowseForCareSearch) {
        const value = commonSearchesLagoon.find(
          ({ psxKeyword }) => search === psxKeyword
        );
        const typeaheadValues = handleOnCommonlySearchedClick({
          item: value,
          source,
          navigate: undefined,
          chipStore,
          setAnalyticsState,
          setPCPSearchState,
          setTypeaheadSearchStore,
          setSearchStore,
          getOnlineRetailers,
          mappingRollUpCodeToCategory,
          enableRetriveTypeAheadData,
        });
        onTypeAheadDataLoaded(typeaheadValues);
      } else if (
        isAutoCompleteSearch ||
        isGlobalSearch ||
        (isRecentsearches && !recentSearchestypeaheadValues)
      ) {
        GetAutoCompleteResults({
          query: encodeURIComponent(searchTerm.toString().trim()),
        });
      } else if (isFacilitiesDrawerSearch || isSpecialtyDrawerSearch) {
        const records = isFacilitiesDrawerSearch ? facilities : specialties;
        const item = records.find(({ name }) => name === search);

        const typeaheadValues = handleOnSpecialtyOrFacilityClick({
          item,
          navigate: undefined,
          setTypeaheadSearchStore,
          setAnalyticsState,
          chipState: chipStore,
          setDetailsStore,
          variant: isFacilitiesDrawerSearch
            ? BrowseAllVariant.Facility
            : BrowseAllVariant.Specialty,
          enableRetriveTypeAheadData,
        });
        onTypeAheadDataLoaded(typeaheadValues);
      } else {
        // fallback to the store if the search is not a common search or an auto complete search
        // TODO: Remaining flows will be implemented in future PRs
        onTypeAheadDataLoaded(typeaheadSearchStore);
      }
    }
  }, [newSearch, facilities.length, specialties.length]);
};
